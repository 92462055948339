import React, { useMemo, useState } from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import { useTheme } from "styled-components"
import GalleryHeader from "@components/klips/gallery-header"
import { GalleryGrid } from "@components/gallery/grid"
import Grid from "@components/elements/grid"
import { Dropdown } from "@components/elements/dropdown"

const KlipsGallery = ({ data, pageContext }) => {
  const gallery = data.gallery.edges
  const services = data.services.edges

  const [selectedService, setSelectedService] = useState("all")
  const [selectedType, setSelectedType] = useState("all")

  const handleServiceChange = event => {
    setSelectedService(event.target.value)
  }

  const handleTypeChange = event => {
    setSelectedType(event.target.value)
  }

  const types = useMemo(() => {
    return Array.from(
      new Set(services.flatMap(({ node: service }) => service.type))
    ).sort()
  }, [services])

  const filteredKlips = useMemo(() => {
    return selectedService === "all" && selectedType === "all"
      ? gallery
      : gallery.filter(({ node: klip }) => {
          const matchesService =
            selectedService === "all" ||
            klip.services.some(service => service.id === selectedService)
          const matchesType =
            selectedType === "all" ||
            klip.services.some(service => service.type?.includes(selectedType))
          return matchesService && matchesType
        })
  }, [selectedService, selectedType, gallery])

  const { color } = useTheme()

  return (
    <Layout
      seo={{
        title:
          "250+ pre-built data connectors for your dashboard. Get connected!",
        description:
          "Want to easily connect your data to a dashboard? Klipfolio has over 400 prebuilt data connectors! Learn how you can connect your favorite services with Klipfolio.",
      }}
      fullWidth={true}
    >
      <article>
        <GalleryHeader
          name={"Klip Gallery"}
          cta={"Track KPIs in minutes"}
          subtitle={
            "Our Klip Gallery allows you to track your key performance measures through Klips with just a few clicks. Get the Klips that matter most to your business right from our Gallery to your dashboard in minutes."
          }
          newsletterCta
          shadow={false}
          kpi
          links={[
            {
              text: "Gallery",
            },
          ]}
        />
        <Div container>
          <Grid
            columns="1fr 1fr"
            columnsSm="1fr"
            gap="2rem"
            padding="2rem"
            margin="0 0 2rem"
            borderRadius="1rem"
            border={`1px solid lightgrey`}
            width="100%"
          >
            <Flex>
              <Paragraph fontSize="1rem" margin="0 0 .5rem">
                Select a Category
              </Paragraph>
              <Dropdown onChange={handleTypeChange}>
                <option value="all">All Categories</option>
                {types.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Dropdown>
            </Flex>
            <Flex>
              <Paragraph fontSize="1rem" margin="0 0 .5rem">
                Select an Integration
              </Paragraph>
              <Dropdown onChange={handleServiceChange}>
                <option value="all">All Services</option>
                {services.map(({ node: service }) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </Dropdown>
            </Flex>
          </Grid>
          <GalleryGrid
            key={`${selectedService}-${selectedType}`}
            klips={filteredKlips}
          />
        </Div>
      </article>
    </Layout>
  )
}

KlipsGallery.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default KlipsGallery

export const pageQuery = graphql`
  query KlipsGalleryTemplate {
    gallery: allGalleryKlips(filter: { status: { eq: "published" } }) {
      edges {
        node {
          image {
            ...imageData
          }
          description
          description_gallery
          services {
            just_docs
            id
            name
            logo {
              ...imageData
            }
            slug
            type
          }
          name
          link
          slug
        }
      }
    }
    services: allGalleryServices(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          name
          logo {
            ...imageData
          }
          slug
          type
        }
      }
    }
  }
`
